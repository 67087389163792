import { getConfig } from '@/api/portal'
/**
 * 放置门户中的后台配置类数据
 */
export default {
  state: {
    // key-value的配置数据
    config: null
  },
  mutations: {
    SET_CONFIG: (state, data) => {
      state.config = data || {}
    }
  },
  actions: {
    LoadConfig({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.config) {
          resolve(state.config)
        } else {
          getConfig()
            .then((res) => {
              if (res.code === 0) {
                commit('SET_CONFIG', res.data)
                resolve(res.data)
              } else {
                reject(new Error(res.message))
              }
            })
            .catch((e) => {
              reject(e)
            })
        }
      })
    }
  }
}
