import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* Layout */
const Layout = () => import('../layout/Layout')
const Framework = () => import('../views/Framework')

export const constantRouterMap = [
  {
    path: '/login/:type',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    hidden: true
  },
  {
    path: '/unifiedPortalLogin',
    component: () => import('@/views/unifiedPortalLogin'),
    hidden: true
  },
  {
    path: '/privateLogin',
    name: 'privateLogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/privateLogin.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Framework,
    hidden: true,
    children: [
      {
        path: '/findPs',
        name: 'FindPassword',
        component: () => import(/* webpackChunkName: "login" */ '../views/FindPassword.vue'),
        hidden: true
      },
      {
        path: '/findpassword',
        name: 'LegalFindPassword',
        component: () => import(/* webpackChunkName: "login" */ '../views/LegalFindPassword.vue'),
        hidden: true
      },
      {
        path: '/updatePs',
        name: 'UpdatePassword',
        component: () => import(/* webpackChunkName: "login" */ '../views/UpdatePassword.vue'),
        hidden: true
      },
      {
        path: '/notary',
        name: 'NotaryOffice',
        component: () => import(/* webpackChunkName: "login" */ '../views/NotaryOffice.vue'),
        hidden: true
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "login" */ '../views/Register.vue'),
        hidden: true
      },
      {
        path: '/approve',
        name: 'Approve',
        component: () => import(/* webpackChunkName: "login" */ '../views/Approve/Index.vue'),
        hidden: true
      },
      {
        path: '/accountInfo',
        name: 'AccountInfo',
        component: () => import(/* webpackChunkName: "login" */ '../views/Client/AccountInfo.vue'),
        hidden: true
      }
    ]
  },
  {
    path: '/home',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/',
        name: 'default',
        hidden: true,
        component: () => import(/* webpackChunkName: "login" */ '../views/default.vue')
      },
      {
        name: '/detail',
        path: '/clientCenter/detail',
        meta: {
          title: '客户详情',
          noCache: true,
          parentPath: '/clientcenter'
        },
        hidden: true,
        component: () => import('@/views/clientCenter/detail')
      },
      {
        name: '/clientIndex',
        path: '/clientCenter/clientCenter/index',
        meta: {
          title: '客户列表',
          noCache: true,
          parentPath: '/clientcenter'
        },
        hidden: true,
        component: () => import('@/views/clientCenter/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: constantRouterMap
})

export default router
