import request from '@/utils/request'
// import { encrypt } from '@/utils/rsa'

export function getInfo(data) {
  return request({
    url: 'login',
    method: 'post',
    data
  })
}

export function getCodeImg() {
  return request({
    url: '/vCode',
    method: 'get'
  })
}

export function findSysBannerPage(data) {
  return request({
    url: 'base/sysBanner/findSysBannerPage',
    method: 'post',
    data
  })
}

export function getClientDetails() {
  return request({
    url: '/user/client/getClientDetails',
    method: 'post'
  })
}

export function openLogin(data) {
  return request({
    url: '/open/login',
    method: 'post',
    data
  })
}

export function userOpenLogin(data) {
  return request({
    url: '/user/account/openLogin',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/logout',
    method: 'get'
  })
}

// 获取单点登录code
export function preOpen(data) {
  return request({
    url: '/portal/apps/preOpen',
    method: 'post',
    data
  })
}

// 获取应用信息接口
export function applicationGetInfo(data) {
  return request({
    url: '/base/application/getInfo',
    method: 'post',
    data
  })
}

// 获取平台url前缀
export function getOpenWebUrl() {
  return request({
    url: 'open/system/getOpenUrl',
    method: 'post'
  })
}

// 客户中心登录
export function login(username, password) {
  return request({
    url: '/clientLogin',
    method: 'post',
    data: {
      username,
      password //jsencrypt.encrypt()
    }
  })
}

// 门户统一登录
export function unifiedPortalLogin(data) {
  return request({
    url: '/unifiedPortalLogin',
    method: 'post',
    data
  })
}

// 取notaryApplicationId
export function getApplicationId() {
  return request({
    url: '/getApplicationId',
    method: 'get'
  })
}
