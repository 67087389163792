import Cookies from 'js-cookie'
import Config from '@/config'

const TokenKey = Config.TokenKey

const ParamTokenKey = Config.ParamTokenKey

export function getToken() {
  let token = Cookies.get(TokenKey)
  if (!token) {
    token = localStorage.getItem(TokenKey) || sessionStorage.getItem(TokenKey)
  }
  return token
}

export function getParamTokenKey() {
  let token = Cookies.get(ParamTokenKey)
  if (!token) {
    token = localStorage.getItem(ParamTokenKey) || sessionStorage.getItem(ParamTokenKey)
  }
  return token
}

export function setParamTokenKey(paramTokenKey) {
  localStorage.setItem(ParamTokenKey, paramTokenKey);
  sessionStorage.setItem(ParamTokenKey, paramTokenKey);
  return Cookies.set(ParamTokenKey, paramTokenKey, { expires: Config.tokenCookieExpires })
}

export function getUserInfo() {
  return { userId: Cookies.get('userId'), applicationClientId: Cookies.get('applicationClientId') }
}

export function setToken(userInfo, rememberMe) {
  localStorage.setItem("userId", userInfo.user.id);
  localStorage.setItem("applicationClientId", userInfo.user.applicationClientId);
  localStorage.setItem(TokenKey, userInfo.token);
  sessionStorage.setItem("userId", userInfo.user.id);
  sessionStorage.setItem("applicationClientId", userInfo.user.applicationClientId);
  sessionStorage.setItem(TokenKey, userInfo.token);
  if (rememberMe) {
    Cookies.set('userId', userInfo.user.id, { expires: Config.tokenCookieExpires })
    Cookies.set('applicationClientId', userInfo.user.applicationClientId, { expires: Config.tokenCookieExpires })
    return Cookies.set(TokenKey, userInfo.token, { expires: Config.tokenCookieExpires })
  } else {
    Cookies.set('userId', userInfo.user.id)
    Cookies.set('applicationClientId', userInfo.user.applicationClientId)
    Cookies.set(TokenKey, userInfo.token)
    return Cookies
  }
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
