import request from '@/utils/request'

export function updatePass(user) {
  const data = {
    oldPass: user.oldPass,
    newPass: user.newPass
  }
  return request({
    url: 'sysUser/updatePass/',
    method: 'post',
    data
  })
}
