export default {
  namespaced: true,
  state: {
    loginWay: 'notary', // notary: 公证处， people: 当事人
    platform: 'notaryCloud', // (notaryCloud-公证云，legalService-专业法律服务，platformOperation-平台运营用户体系）
    loginBtn: true
  },
  mutations: {
    setLoginWay: (state, data) => {
      state.loginWay = data
    },
    setPlatform: (state, data) => {
      state.platform = data
    },
    setLoginBtn: (state, data) => {
      state.loginBtn = data
    }
  },
  actions: {}
}
