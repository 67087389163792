<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <el-menu
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      :unique-opened="$store.state.settings.uniqueOpened"
      mode="vertical"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
    >
      <Logo :is-collapse="isCollapse" />
      <h1 v-if="!isCollapse" class="sidebar-title">客户中心</h1>
      <sidebar-item v-for="route in permissionRouters" :key="route.path" :item="route" :base-path="route.path" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import Logo from './Logo'
export default {
  components: { SidebarItem, Logo },
  data() {
    return {
      permissionRouters: []
    }
  },
  created() {
    // 暂时写死路由只获取了客户中心
    this.permissionRouters = this.permission_routers
  },
  computed: {
    ...mapGetters(['permission_routers', 'sidebar']),
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
<style lang="scss" scoped>
.sidebar-title {
  height: 50px;
  color: #fff;
  font-size: 14px;
  text-align: center;

  line-height: 50px;
  padding-right: 24px;
}
</style>
