<template>
  <el-menu-item v-if="sidebarLogo && !unifiedPortal" :class="{ 'submenu-title-noDropdownlogo': isCollapse }" index="0">
    <!-- 缩小时显示的logo，可以自定义，这里直接使用图标库中的 -->
    <svg-icon v-if="isCollapse" icon-class="run" />
    <!--正常状态下显示的，可以使用本地的logoImg-->
    <el-row>
      <el-col :span="8">
        <div class="logo-con">
          <!--<img src="https://aurora-1255840532.cos.ap-chengdu.myqcloud.com/logo.png">-->
          <img :src="logoImg" style="width: 35px; height: 35px" />
        </div>
      </el-col>
      <el-col :span="16">
        <div style="height: 18px; color: white; font-size: 20px; text-align: center">法信公证云</div>
        <div style="height: 4px; color: white; font-size: 12px; text-align: center">JUSTICE & FAITH</div>
      </el-col>
    </el-row>
  </el-menu-item>
</template>

<script>
import logoImg from '@/assets/images/logo.png'
import store from '../../../store'
import { mapState } from 'vuex'
export default {
  name: 'Logo',
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      logoImg: logoImg,
      unifiedPortal: store.getters.userInfo.unifiedPortal
    }
  },
  mounted: function() {
    this.unifiedPortal = store.getters.userInfo.unifiedPortal
  },
  computed: {
    ...mapState({
      sidebarLogo: state => state.settings.sidebarLogo
    })
  }
}
</script>

<style scoped></style>
