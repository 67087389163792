import { openLogin, userOpenLogin, logout, login, unifiedPortalLogin } from '@/api/login'
import { getInfo } from '@/api/menu'
import { getToken, setToken, removeToken } from '@/utils/auth'

const LOGIN_EXP_TIMESTAMP = 'LOGIN_EXP_TIMESTAMP'
const loginExpTimeStamp = window.localStorage.getItem(LOGIN_EXP_TIMESTAMP)

const user = {
  state: {
    token: getToken(),
    user: {},
    roles: [],
    userInfo: {},
    // 第一次加载菜单时用到
    loadMenus: false,
    loginExpTimeStamp: loginExpTimeStamp || '',
    needFindMenus: false
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_LOAD_MENUS: (state, loadMenus) => {
      state.loadMenus = loadMenus
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_LOGIN_EXP_TIME: (state, expTime) => {
      state.loginExpTimeStamp = expTime || new Date().getTime()
      window.localStorage.setItem(LOGIN_EXP_TIMESTAMP, state.loginExpTimeStamp)
    },
    setNeedFindMenus: (state, bool) => {
      state.needFindMenus = bool
    }
  },

  actions: {
    // 客户中心登录
    Login({ commit }, userInfo) {
      const rememberMe = userInfo.rememberMe
      // console.log(userInfo)
      return new Promise((resolve, reject) => {
        login(userInfo.username, userInfo.password)
          .then((res) => {
            if (res.code && res.code !== 0) {
              // 登录输入错误的情况判断
              resolve(res)
            } else {
              setToken(res.data, rememberMe)
              commit('SET_TOKEN', res.data.token)
              commit('SET_USER', res.data.user)
              commit('SET_USER_INFO', {
                unifiedPortal: false,
                hideLayout: false
              })
              // setUserInfo(res.user, commit)
              // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
              commit('SET_LOAD_MENUS', true)
              resolve()
            }
          })
          .catch((error) => {
            removeToken()
            reject(error)
          })
      })
    },
    // 统一门户登陆
    UnifiedPortalLogin({ commit }, params) {
      return new Promise((resolve, reject) => {
        unifiedPortalLogin(params)
          .then((res) => {
            if (res.code && res.code !== 0) {
              // 登录输入错误的情况判断
              resolve(res)
            } else {
              let userInfo = {
                user: {
                  id: res.user.user_id,
                  applicationClientId: res.application_client_id,
                  applicationId: res.user.application_id
                },
                token: res.access_token
              }
              setToken(userInfo, false)
              commit('SET_USER', userInfo.user)
              commit('SET_USER_INFO', {
                unifiedPortal: true,
                hideLayout: params.redirectUri.indexOf('hideLayout=true') > -1
              })
              commit('SET_LOGIN_EXP_TIME', res.tokenExpireTime)
              commit('SET_TOKEN', res.token)
              // setUserInfo(res.user, commit)
              // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
              commit('SET_LOAD_MENUS', true)
              resolve(res)
            }
          })
          .catch((error) => {
            removeToken()
            reject(error)
          })
      })
    },
    // 登录
    OpenLogin({ commit }, userInfo) {
      const rememberMe = userInfo.rememberMe
      return new Promise((resolve, reject) => {
        openLogin(userInfo)
          .then((res) => {
            if (res.code && res.code !== 0) {
              // 登录输入错误的情况判断
              resolve(res)
            } else {
              setToken(res.data, rememberMe)
              commit('SET_TOKEN', res.data.token)
              setUserInfo(res.data.user, commit)
              resolve()
            }
          })
          .catch((error) => {
            removeToken()
            reject(error)
          })
      })
    },
    UserOpenLogin({ commit }, userInfo) {
      const rememberMe = userInfo.rememberMe
      return new Promise((resolve, reject) => {
        userOpenLogin(userInfo)
          .then((res) => {
            if (res.code && res.code !== 0) {
              // 登录输入错误的情况判断
              resolve(res)
            } else {
              setToken(res.data, rememberMe)
              commit('SET_TOKEN', res.data.token)
              setUserInfo(res.data.user, commit)
              resolve()
            }
          })
          .catch((error) => {
            removeToken()
            reject(error)
          })
      })
    },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logout()
          .then((res) => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            if (res.code && res.code !== 0) {
              resolve(res)
            } else {
              reject()
            }
          })
          .catch((error) => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            reject(error)
          })
      })
    },

    updateLoadMenus({ commit }) {
      return new Promise(() => {
        commit('SET_LOAD_MENUS', false)
      })
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            setUserInfo(res, commit)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}

export const setUserInfo = (res, commit) => {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (!res.roles || res.roles.length === 0) {
    // 目前角色没编码，默认管理员
    commit('SET_ROLES', ['ADMIN'])
  } else {
    commit('SET_ROLES', res.roles)
  }
  commit('SET_USER', res)
}

export default user
