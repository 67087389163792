import router from './routers'
import store from '@/store'
import { getToken } from '@/utils/auth' // getToken from cookie
import { buildMenus } from '@/api/menu'
import { filterAsyncRouter } from '@/store/modules/permission'
const whiteList = ['/login', '/unifiedPortalLogin', '/privateLogin', '/findPs', '/findpassword', '/updatePs', '/notary', '/register', '/approve', '/accountInfo']
let isRefresh = true
router.beforeEach((to, from, next) => {
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/privateLogin') {
      next({ path: '/home' })
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store
          .dispatch('GetInfo')
          .then(() => {
            // 拉取user_info
            // 动态路由，拉取菜单
            let reload = (from.fullPath === '/' && !store.getters.loadMenus) || from.path === '/privateLogin' || store.getters.loadMenus
            if (reload && isRefresh) {
              isRefresh = false
              store.dispatch('updateLoadMenus')
              loadMenus(next, to)
            }
          })
          .catch(() => {
            store.dispatch('LogOut').then(() => {
              localStorage.clear()
              sessionStorage.clear()
              location.reload() // 为了重新实例化vue-router对象 避免bug
            })
          })
        // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus && store.getters.addRouters.length === 0) {
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus').then(() => {})
        loadMenus(next, to)
      } else {
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1 || to.path.indexOf('/login/') > -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/privateLogin?redirect=${to.path}`) // 否则全部重定向到登录页
    }
  }
})


export const loadMenus = (next, to) => {
  buildMenus(store.getters.user.id).then(res => {
    isRefresh = true
    const asyncRouter = filterAsyncRouter(res.data.menus)
    asyncRouter.push({ path: '*', redirect: '/404', hidden: true })
    store.dispatch('GenerateRoutes', asyncRouter).then(() => {
      // 存储路由
      router.addRoutes(asyncRouter) // 动态添加可访问路由表
      if (store.state.user.needFindMenus) {
        store.commit('setNeedFindMenus', false)
        const path = getMenus(to.path.substr(1))
        next({ path, replace: true })
      } else {
        next({ ...to, replace: true })
      }
    })
  })
}

const getMenus = path => {
  const menu = store.getters.addRouters
  let yuanTotalMenuUrl = ''
  let menuUrl = ''
  const forEachMenu = (children, targetValue, totalMenuUrl) => {
    for (let i = 0; i < children.length; i++) {
      let totalMenuUrl1 = ''
      totalMenuUrl1 = totalMenuUrl
      const element = children[i]
      if (element.children !== undefined) {
        totalMenuUrl1 = totalMenuUrl1 + '/' + element.path
        forEachMenu(element.children, targetValue, totalMenuUrl1)
      } else if (element.id === targetValue) {
        menuUrl = totalMenuUrl1 + '/' + element.path
        break
      } else {
        totalMenuUrl1 = yuanTotalMenuUrl
      }
    }
  }

  menu.forEach(element => {
    if (element.children !== undefined) {
      yuanTotalMenuUrl = element.path
      forEachMenu(element.children, path, yuanTotalMenuUrl)
    }
    if (menuUrl === '' && menuUrl === undefined) {
      menuUrl = element.path
    }
  })
  return menuUrl || '/' + path
}