import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/login'
import user from './modules/user'
import portal from './modules/portal'
import app from './modules/app'
import tagsView from './modules/tagsView'
import settings from './modules/settings'
import permission from './modules/permission'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    user,
    portal,
    app,
    tagsView,
    settings,
    permission
  },
  getters
})
